
import { Client, ClientInfoResponse, Domain } from "@/types/responses"
const ApiError = () => import("@/common/ApiError.vue").then(m => m.default || m)
const SelectPrimaryContact = () => import("@/components/customer/SelectPrimaryContact.vue").then(m => m.default || m)
import Vue from "vue"

// NB! For jest purposes. No actual impact on import.
/* eslint-disable */
// @ts-ignore
import { required } from "vee-validate/dist/rules.umd.js"
/* eslint-enable */

import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate"
import { CompanyInfo } from "@/types/responses"


const isValidName = (name: string) => {
	/*const regExp = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 ,.!?:;/'(){}&---+*÷=%]*$/g;
	if(!regExp.test(name)) {
		return false
	}
	*/
	return true;
}

const isValidDescription = (description: string) => {
	/*const regExp = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 !@#$%^&*()_+=/\---{}'".,<>:;~`åæø]*$/g;
	if(!regExp.test(description)) {
		return false
	}
	*/
	return true;
}

extend("name", {
	validate: (value) => isValidName(value),
	message: "The name contains invalid characters",
})

extend("description", {
	validate: (value) => isValidDescription(value),
	message: "The description contains invalid characters",
})

extend("required", {
	...required,
	message: "This field cannot be empty",
})

export default Vue.extend({
	name: "ClientInfo",
	components: {
		ApiError,
		SelectPrimaryContact,
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		clientPersons: {
			type: Boolean,
			required: true
		}
	},
    async mounted() {
		if(!this.hasFetchedInfo()){
			await this.refreshClientInfo();
		}

		this.loaded = true;

		if(this.hasFetchedInfo()){
			this.setFieldsEditableFields();
			this.setOrgFieldsEditableFields();
		}
	},
    data(): {
		loadError: boolean
		error: string | null | undefined

        snackBar: boolean
		snackBarText: string
		timeLeft: number

        loaded: boolean
		loading: boolean
		nameLoading: boolean
		descLoading: boolean
		orgName: string | null | undefined
		orgDescription: string | null | undefined
		newName: string | null | undefined
		newDescription: string | null | undefined

		bgField: string
    } {
        return {
			loadError: false,
			error: null,

            snackBar: false,
			snackBarText: "",
			timeLeft: 2,

            loaded: false,
			loading: false,
			nameLoading: false,
			descLoading: false,
			orgName: "",
			orgDescription: "",
			newName: "",
			newDescription: "",

			bgField: "rgba(241, 241, 241, 0.65)"
        }
    },
	watch: {
        getSelectedClientInfo: {
            deep: true,
            handler(){
                console.log("updated client info")
            }
        }
	},
	computed: {
		getSelectedClient(): Client | null {
			if(this.loaded) return this.$store.state.context.selectedClient;
            return {} as Client;
		},
        getSelectedClientInfo(): ClientInfoResponse | null {
            if(this.loaded) return this.$store.state.context.selectedClientInfo;
            return {} as ClientInfoResponse;
		},
	},
	methods: {
        hasDefault(): boolean{
            if(this.$store.state.context.selectedClient == null){
                return false // Dont load unnessecarily if user has not chosen a client
            }
            return true
        },
        async refreshClientInfo(): Promise<void> {
			try{
				await this.$vStore.dispatch("context/getClientInfo")
            	const hitCustomerClient = await this.$vStore.state.context.customerClients.find(client => client.id === this.$vStore.state.context.selectedClient!.id)
                const hitAssociatedClient = await this.$vStore.state.context.associatedClients.find(client => client.id === this.$vStore.state.context.selectedClient!.id)
            	if(hitCustomerClient) await this.$vStore.dispatch("context/setClient", hitCustomerClient)
                else if(hitAssociatedClient) await this.$vStore.dispatch("context/setClient", hitAssociatedClient)
			} catch(e) {
				console.warn("clientInfo: " + e)
				this.loadError = true;
				if(e && e instanceof Error) this.error = e.message;
				else  this.error = "Error: Unknown error"
			}
			
		},
		hasFetchedInfo(): boolean {
			if(this.$store.state.context!.selectedClientInfo){
                if(
                    this.$store.state.context!.selectedClient!.id == 
                    this.$store.state.context!.selectedClientInfo!.id
                ) return true
                return false;
			} else {
				return false;
			}
		},
        async nameFieldEnter(): Promise<void> {
			if(this.newName == this.orgName) return
			const errors = await (this.$refs as any).customerObserver1.errors!.newName.length;
			if(errors > 0) return

			const newCompanyInfo: CompanyInfo = {
				name: this.newName,
				description: null,
				businessRegistryId: null
			}
			this.save(newCompanyInfo)			
		},
		async descFieldEnter(): Promise<void> {
			if(this.newDescription == this.orgDescription) return
			const errors = await (this.$refs as any).customerObserver2.errors!.newDescription.length;
			if(errors > 0) return
			
			const newCompanyInfo: CompanyInfo = {
				name: null,
				description: this.newDescription,
				businessRegistryId: null
			}
			this.save(newCompanyInfo)		
		},
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			this.snackBarText = `Successfully updated company info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
			return new Promise((resolve) => {
				const uploadTimer = setInterval(() => {
					this.timeLeft--
					this.snackBarText = `Successfully updated company info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
					if (this.timeLeft <= 0) {
						clearInterval(uploadTimer)
						resolve()
					}
				}, 1000)
			})
		},
		setOrgFieldsEditableFields(): void {
			// eslint-disable-next-line
			this.orgName = this.getSelectedClientInfo!.name
			// eslint-disable-next-line
			this.orgDescription = this.getSelectedClientInfo!.description
		},
		setFieldsEditableFields(): void {
			// eslint-disable-next-line
			this.newName = this.getSelectedClientInfo!.name
			// eslint-disable-next-line
			this.newDescription = this.getSelectedClientInfo!.description
		},
		disabled(): boolean {
			return false
		},
		async save(companyInfo: CompanyInfo): Promise<void> {
			if (this.loading) return
			this.loading = true
			try {
				if(companyInfo.name){ 
					this.nameLoading = true;
					await this.$vStore.dispatch("context/setClientName", companyInfo)
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.customerObserver1) { ;(this.$refs as any).customerObserver1.reset() }
					})
					this.orgName = this.newName;
					
				} else if (companyInfo.description) {
					// eslint-disable-next-line
					this.descLoading = true;
					await this.$vStore.dispatch("context/setClientDescription", companyInfo)
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.customerObserver2) { ;(this.$refs as any).customerObserver2.reset() }
					})
					this.orgDescription = this.newDescription;
				}

				// Lock loading for snackbar reload page
				this.snackBarText = `Successfully updated client info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
				this.snackBar = true
				await this.countDownTimer()
                
				await this.refreshClientInfo()

				// Stop all loading
				this.nameLoading = false;
				this.descLoading = false;
				this.loading = false;
			} catch (e) {
				this.snackBarText = "Could not update client info.<br/><br/>Error: " + JSON.stringify(e)
				this.snackBar = true
				
				// Stop all loading
				this.nameLoading = false;
				this.descLoading = false;
				this.loading = false
			}
		},

		retry(): void{
			//this.mounted()
		}
	},
})
